<template>
    <div>
     <div class="row">
         <div class="col-lg-6">
             <b-button :href="exportexcell" @click="exportexcel" variant="primary"><i class="fa fa-download" aria-hidden="true"/>Excel </b-button>
         </div>
         <div class="col-lg-6"></div>
     </div>
     <br>
     <div class="row">
          <div class="col-lg-4">
              <div class="card card-custom gutter-b">
                  <div class="card-body" style="position: relative;">
                      <center><h4>TOTAL</h4></center>
                          <div id="chart_12">
                              <apexchart type="bar" height="280" :options="chartOptions8" :series="series8"></apexchart>
                          </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-8 row">
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PM PTSP PROVINSI</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions" :series="series"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PM PPB PROVINSI</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions4" :series="series4"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PM PTSP KABUPATEN</h4></center>
                              <div id="chart">
                                  <apexchart type="donut" height="200" :options="chartOptions2" :series="series2"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PM PPB KABUPATEN</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions5" :series="series5"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PM PTSP KOTA</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions3" :series="series3"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PM PPB KOTA</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions6" :series="series6"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PPB KL</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions7" :series="series7"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
          </div>
     </div>
     <hr style="border-top: 1px solid black; margin-top: -10px">
     <div class="row">
         <div class="col-lg-4">
              
          </div>
          <div class="col-lg-8 row">
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PEMANGKU KEPENTINGAN HIPMI</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions9" :series="series9"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH</h4></center>
                          <center><h4>PEMANGKU KEPENTINGAN PROVINSI</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions10" :series="series10"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
          </div>
     </div>
     <hr style="border-top: 1px solid black; margin-top: -10px">
     <div class="row">
         <div class="col-lg-4">
              
          </div>
          <div class="col-lg-8 row">
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH VERIFIKASI</h4></center>
                          <center><h4>PM PTSP PROVINSI</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions11" :series="series11"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH VERIFIKASI</h4></center>
                          <center><h4>PM PPB PROVINSI</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions14" :series="series14"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card card-custom gutter-b">
                      <div class="card-body">
                          <center><h4>JUMLAH VERIFIKASI</h4></center>
                          <center><h4>PM PTSP KABUPATEN</h4></center>
                              <div id="chart">
                                  <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                  <apexchart type="donut" height="200" :options="chartOptions12" :series="series12"></apexchart>
                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                      <div class="card card-custom gutter-b">
                          <div class="card-body">
                              <center><h4>JUMLAH VERIFIKASI</h4></center>
                              <center><h4>PM PPB KABUPATEN</h4></center>
                                  <div id="chart">
                                      <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                      <apexchart type="donut" height="200" :options="chartOptions15" :series="series15"></apexchart>
                                  </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="card card-custom gutter-b">
                          <div class="card-body">
                              <center><h4>JUMLAH VERIFIKASI</h4></center>
                              <center><h4>PM PTSP KOTA</h4></center>
                                  <div id="chart">
                                      <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                      <apexchart type="donut" height="200" :options="chartOptions13" :series="series13"></apexchart>
                                  </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="card card-custom gutter-b">
                          <div class="card-body">
                              <center><h4>JUMLAH VERIFIKASI</h4></center>
                              <center><h4>PM PPB KOTA</h4></center>
                                  <div id="chart">
                                      <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                      <apexchart type="donut" height="200" :options="chartOptions16" :series="series16"></apexchart>
                                  </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="card card-custom gutter-b">
                          <div class="card-body">
                              <center><h4>JUMLAH VERIFIKASI</h4></center>
                              <center><h4>PPB KL</h4></center>
                                  <div id="chart">
                                      <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                                      <apexchart type="donut" height="200" :options="chartOptions17" :series="series17"></apexchart>
                                  </div>
                          </div>
                      </div>
                  </div>
          </div>
     </div>
    </div>
  </template>
  <style>
    div.messages {
    /* background-color: lightblue; */
    /* width: 110px; */
    height: 500px;
    overflow: auto;
  }
  h6{
      height: 17px;
      text-align: center;
  }
  </style>
  <style lang="">
    
  </style>
  <script src="https://code.jquery.com/jquery-3.5.1.js"></script>
  <script src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js"></script>
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import axios from 'axios';
  import VueApexCharts from 'vue-apexcharts';
  // import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
  // import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
  // import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
  // import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
  // import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
  // import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
  // import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
  // import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
  // import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
  // import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
  // import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";
  
  export default {
    name: "ringkasan",
    data() {
        return {
          user: JSON.parse(localStorage.getItem('user')),
          test:'hai',
          url: localStorage.getItem('baseapi'),
          token: localStorage.getItem('id_token'),
          year: new Date().getFullYear(),
          exportexcell: '',
          chart:[],
          chart2:[],
          chart3:[],
          chart4:[],
          chart5:[],
          chart6:[],
          chart7:[],
          series: [],
          chartOptions: {
              chart: {
              //   height: 380,
                  type: 'donut',
              },
              legend: {
                  position: 'bottom',
              },
              labels: [],
              colors:['#F04E60', '#F4A803', '#4DC5BD'],
              // responsive: [{
              //   breakpoint: 480,
              //   options: {
              //     chart: {
              //       width: 200
              //     },
              //     legend: {
              //       position: 'bottom'
              //     }
              //   }
              // }]
              plotOptions: {
                  donut: {
                  size: '100%'
                  }
              }
          },
  
          series2: [],
              chartOptions2: {
              chart: {
              //   height: 380,
                  type: 'donut',
              },
              legend: {
                  position: 'bottom',
              },
              labels: [],
              colors:['#F04E60', '#F4A803', '#4DC5BD'],
              plotOptions: {
                  donut: {
                  size: '100%'
                  }
              },
          },
  
          series3: [],
          chartOptions3: {
              chart: {
              //   height: 380,
                  type: 'donut',
              },
              legend: {
                  position: 'bottom',
              },
              labels: [],
              colors:['#F04E60', '#F4A803', '#4DC5BD'],
              plotOptions: {
                  donut: {
                  size: '100%'
                  }
              }
          },
  
          series4: [],
          chartOptions4: {
              chart: {
              //   height: 380,
                  type: 'donut',
              },
              legend: {
                  position: 'bottom',
              },
              labels: [],
              colors:['#F04E60', '#F4A803', '#4DC5BD'],
              plotOptions: {
                  donut: {
                  size: '100%'
                  }
              }
          },
  
          series5: [],
          chartOptions5: {
              chart: {
              //   height: 380,
                  type: 'donut',
              },
              legend: {
                  position: 'bottom',
              },
              labels: [],
              colors:['#F04E60', '#F4A803', '#4DC5BD'],
              plotOptions: {
                  donut: {
                  size: '100%'
                  }
              }
          },
  
          series6: [],
          chartOptions6: {
              chart: {
              //   height: 380,
                  type: 'donut',
              },
              legend: {
                  position: 'bottom',
              },
              labels: [],
              colors:['#F04E60', '#F4A803', '#4DC5BD'],
              plotOptions: {
                  donut: {
                  size: '100%'
                  }
              }
          },
  
          series7: [],
          chartOptions7: {
              chart: {
              //   height: 380,
                  type: 'donut',
              },
              legend: {
                  position: 'bottom',
              },
              labels: [],
              colors:['#F04E60', '#F4A803', '#4DC5BD'],
              plotOptions: {
                  donut: {
                  size: '100%'
                  }
              }
          },
  
          series8: [],
            chartOptions8: {
                legend: {
                  show: false
              },
              chart: {
                toolbar: {
                  show: false
                 },
                height: 350,
                type: 'bar',
              },
              plotOptions: {
                bar: {
                  barHeight: '100%',
                  distributed: true,
                  horizontal: true,
                  dataLabels: {
                    position: 'bottom'
                  },
                }
              },
              dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                  colors: ['#fff']
                },
                formatter: function (val, opt) {
                  return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                },
                offsetX: 0,
                dropShadow: {
                  enabled: true
                }
              },
              stroke: {
                width: 3,
                colors: ['#fff']
              },
              xaxis: {
                categories: ['Provinsi', 'Kabupaten', 'Kota', 'KL', 'Pemangku Kepentingan HIPMI','Pemangku Kepentingan Provinsi'
                ],
              },
              yaxis: {
                labels: {
                  show: false
                }
              },
              tooltip: {
                theme: 'dark',
                x: {
                  show: false
                },
                y: {
                  title: {
                    formatter: function () {
                      return ''
                    }
                  }
                }
              },
              colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5', '#4DC5BD', '#F4A803'],
              responsive: [{
                breakpoint: 480,
                options: {
                  legend: {
                      show: false
                  }
                }
              }]
              },
              // responsive: [{
              //   breakpoint: 480,
              //   options: {
              //     legend: {
              //         show: false
              //     }
              //   }
              // }]
            series9: [],
              chartOptions9: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#F4A803', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
            series10: [],
              chartOptions10: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#F4A803', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
  
            series11: [],
              chartOptions11: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
  
            series12: [],
              chartOptions12: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
  
            series13: [],
              chartOptions13: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
  
            series14: [],
              chartOptions14: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
  
            series15: [],
              chartOptions15: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
  
            series16: [],
              chartOptions16: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
  
            series17: [],
              chartOptions17: {
                  chart: {
                  //   height: 380,
                      type: 'donut',
                  },
                  legend: {
                      position: 'bottom',
                  },
                  labels: [],
                  colors:['#F04E60', '#4DC5BD'],
                  plotOptions: {
                      donut: {
                      size: '100%'
                      }
                  }
            },
            
        }
    },
    components: {
      
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ringkasan" }]);
      this.loaddata()
    },
    methods: {
      loaddata(){
          if (this.user.id_role === 'super_user' || this.user.id_role === 'tim_penilai' || this.user.id_role === 'operator_nomine' || this.user.id_role === 'qc' || this.user.id_role === 'tim_teknis_penilai' || this.user.id_role === 'dpb' || this.user.id_role === 'kemenkeu') {
              axios.get(this.url+"/activity_chart",{
                headers: {
                    'xth': this.token
                }
              })
              .then(response => {
                  this.chart = response.data.jumlah_pm_prov;
                  this.chart2 = response.data.jumlah_pm_kab;
                  this.chart3 = response.data.jumlah_pm_kot;
                  this.chart4 = response.data.jumlah_ppb_prov;
                  this.chart5 = response.data.jumlah_ppb_kab;
                  this.chart6 = response.data.jumlah_ppb_kot;
                  this.chart7 = response.data.jumlah_ppb_kl;
                  this.chart8 = response.data.total;
                  this.chart9 = response.data.jumlah_asosiasi;
                  this.chart10 = response.data.jumlah_pk;
                  this.chart11 = response.data.jumlah_pm_prov_ver;
                  this.chart12 = response.data.jumlah_pm_kab_ver;
                  this.chart13 = response.data.jumlah_pm_kot_ver;
                  this.chart14 = response.data.jumlah_ppb_prov_ver;
                  this.chart15 = response.data.jumlah_ppb_kab_ver;
                  this.chart16 = response.data.jumlah_ppb_kot_ver;
                  this.chart17 = response.data.jumlah_ppb_kl_ver;
                  // this.idpenilaian=response.data.data.id;
                  for (var prop in this.chart) {
                      this.series.push( this.chart[prop] );
                  }
                  for (var prop in this.chart) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions.labels.push( ini + " = " + this.chart[prop]);
                  }
  
                  for (var prop in this.chart2) {
                      this.series2.push( this.chart2[prop] );
                  }
                  for (var prop in this.chart2) {
                     var ini=prop.replace("_"," ");
                      this.chartOptions2.labels.push( ini + " = " + this.chart2[prop]);
                  }
  
                  for (var prop in this.chart3) {
                      this.series3.push( this.chart3[prop] );
                  }
                  for (var prop in this.chart3) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions3.labels.push( ini + " = " + this.chart3[prop]);
                  }
                  
                  for (var prop in this.chart4) {
                      this.series4.push( this.chart4[prop] );
                  }
                  for (var prop in this.chart4) {
                     var ini=prop.replace("_"," ");
                      this.chartOptions4.labels.push( ini + " = " + this.chart4[prop]);
                  }
  
                  for (var prop in this.chart5) {
                      this.series5.push( this.chart5[prop] );
                  }
                  for (var prop in this.chart5) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions5.labels.push( ini + " = " + this.chart5[prop]);
                  }
  
                  for (var prop in this.chart6) {
                      this.series6.push( this.chart6[prop] );
                  }
                  for (var prop in this.chart6) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions6.labels.push( ini + " = " + this.chart6[prop]);
                  }
  
                  for (var prop in this.chart7) {
                      this.series7.push( this.chart7[prop] );
                  }
                  for (var prop in this.chart7) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions7.labels.push( ini + " = " + this.chart7[prop]);
                  }
  
                  var datachart = []
                  for (var prop in this.chart8) {
                      datachart.push( this.chart8[prop] );
                      // console.log(datachart)
                  }
                  this.series8.push( {"data": datachart})
                  
                  for (var prop in this.chart9) {
                      this.series9.push( this.chart9[prop] );
                  }
                  for (var prop in this.chart9) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions9.labels.push( ini + " = " + this.chart9[prop]);
                  }
  
                  for (var prop in this.chart10) {
                      this.series10.push( this.chart10[prop] );
                  }
                  for (var prop in this.chart10) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions10.labels.push( ini + " = " + this.chart10[prop]);
                  }
  
                  for (var prop in this.chart11) {
                      this.series11.push( this.chart11[prop] );
                  }
                  for (var prop in this.chart11) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions11.labels.push( ini + " = " + this.chart11[prop]);
                  }
  
                  for (var prop in this.chart12) {
                      this.series12.push( this.chart12[prop] );
                  }
                  for (var prop in this.chart12) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions12.labels.push( ini + " = " + this.chart12[prop]);
                  }
  
                  for (var prop in this.chart13) {
                      this.series13.push( this.chart13[prop] );
                  }
                  for (var prop in this.chart13) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions13.labels.push( ini + " = " + this.chart13[prop]);
                  }
  
                  for (var prop in this.chart14) {
                      this.series14.push( this.chart14[prop] );
                  }
                  for (var prop in this.chart14) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions14.labels.push( ini + " = " + this.chart14[prop]);
                  }
  
                  for (var prop in this.chart15) {
                      this.series15.push( this.chart15[prop] );
                  }
                  for (var prop in this.chart15) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions15.labels.push( ini + " = " + this.chart15[prop]);
                  }
  
                  for (var prop in this.chart16) {
                      this.series16.push( this.chart16[prop] );
                  }
                  for (var prop in this.chart16) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions16.labels.push( ini + " = " + this.chart16[prop]);
                  }
  
                  for (var prop in this.chart17) {
                      this.series17.push( this.chart17[prop] );
                  }
                  for (var prop in this.chart17) {
                      var ini=prop.replace("_"," ");
                      this.chartOptions17.labels.push( ini + " = " + this.chart17[prop]);
                  }
              }).catch(error=>{
                  return error
                  // this.loaddata()
                  });
          } else {
              this.$router.push({ name: "dashboard" })
          }
  
              
      },
      exportexcel(year){
            this.exportexcell = this.url+`/export/activity/`+ this.year
        },
      setActiveTab1(event) {
        this.tabIndex = this.setActiveTab(event);
      },
      setActiveTab2(event) {
        this.tabIndex2 = this.setActiveTab(event);
      },
      /**
       * Set current active on click
       * @param event
       */
      setActiveTab(event) {
        // get all tab links
        const tab = event.target.closest('[role="tablist"]');
        const links = tab.querySelectorAll(".nav-link");
        // remove active tab links
        for (let i = 0; i < links.length; i++) {
          links[i].classList.remove("active");
        }
  
        // set current active tab
        event.target.classList.add("active");
  
        // set clicked tab index to bootstrap tab
        return parseInt(event.target.getAttribute("data-tab"));
      }
    },
    created(){
        this.exportexcel()
      //   this.getuser(this.user)
      // this.getlocaldata()
    }
  };
  </script>
  